////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-secondaryColor {
  background-color: rgba(0, 0, 0, 1);
}
.bg-thirdColor {
  background-color: rgba(0, 0, 0, 1);
}
.bg-fourthColor {
  background-color: rgba(246, 213, 11, 1);
}
.bg-fifthColor {
  background-color: rgba(246, 213, 11, 0.72);
}
.bg-sixthColor {
  background-color: rgba(66, 66, 66, 1);
}
.bg-seventhColor {
  background-color: rgba(194, 21, 34, 1);
}
